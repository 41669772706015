var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('UserAddEdit',{attrs:{"es-id":_vm.ae.esId,"stepper-mode":_vm.ae.stepperMode,"stepper-step":_vm.ae.stepperStep,"user-id":_vm.ae.userId,"user-data":_vm.ae.userData}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"px-4 content-spacing"},[_c('title-plus',{staticClass:"mb-10",attrs:{"title":"Organization Users"},on:{"plus":_vm.onOpenES}}),_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2"},[_c('div',[_c('oto-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"flex md:justify-end "},[_c('export-as-dropdown')],1)])],1),_c('fsTableContainer',{staticClass:"px-4 vld-parent"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('fsTable',[_c('fsTableHead',_vm._l((_vm.tableHeaders),function(item,index){return _c('fsTableHeadItem',{key:("fs-table-header-" + index),attrs:{"item":item},on:{"apply-sorting":function($event){return _vm.handleSorting($event)}}})}),1),_vm._l((_vm.indexData),function(item,index){return _c('fsTableRow',{key:("fs-table-row-" + index)},[_c('fsTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                name: 'ViewOrganizationUserProfile',
                params: { id: item.id },
              },"target":"_blank"}},[_vm._v(" #"+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])],1),_c('fsTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                name: _vm.getDetailsPageRouteName(
                  item.role ? item.role.role_name : null
                ),
                params: { id: item.id },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(item.full_name)+" ")])],1),_c('fsTableRowItem',[_vm._v(_vm._s(item.organization_name))]),_c('fsTableRowItem',[_vm._v(_vm._s(item.phone_number))]),_c('fsTableRowItem',[_vm._v(_vm._s(item.email))]),_c('fsTableRowItem',[_vm._v(" "+_vm._s(item.role ? item.role.role_name : '--')+" ")]),_c('fsTableRowItem',[_c('XStatus',{attrs:{"variant":item.user_status === 'A'
                  ? 'green'
                  : item.user_status === 'D'
                  ? 'gray'
                  : 'orange',"text":item.user_status === 'A'
                  ? 'Active'
                  : item.user_status === 'D'
                  ? 'Inactive'
                  : item.user_status === 'H'
                  ? 'On Hold'
                  : '--',"profile":"user"}})],1),_c('fsTableRowItem',[(item.last_login)?_c('div',[_vm._v(" "+_vm._s(_vm._f("friendlyDateTime")(item.last_login))+" ")]):_c('div',[_vm._v("-")])]),_c('fsTableRowItem',[_c('div',{staticClass:"flex items-center gap-3"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.onOpenES(item.id)}}}),_c('UserIndexActions',{attrs:{"primary-key":index,"data":item},on:{"accountStatusUpdated":function($event){return _vm.getIndexData(_vm.getQueryString)}}})],1)])],1)})],2),_c('fs-table-foot',{attrs:{"total-items":_vm.indexMetaData.count.total,"pagination-summary":_vm.paginationSummary}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }